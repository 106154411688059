// 
// _tables.scss
// 


.table {
  th{
    color: $gray-700;
    font-weight: 500;
    vertical-align: middle;
    border-color: $table-border-color;
  }
  td {
    font-weight: 400;
    vertical-align: middle;
    border-color: $table-border-color;
  }
  &.border-dashed{
    th {
      font-weight: 500;
      border-top: 1px dashed $table-border-color;      
    }
    td{
      border-top: 1px dashed $table-border-color;
    }
    thead {
      th {
        border-bottom: 1px dashed $table-border-color;
      }
    }
  }
  
  .thead-light {
    th{
      color: $gray-700;
      background-color: $gray-200;
      border-color: $table-border-color;
    }
  }
  &.table-dark {
    color: $gray-100;
    th{
      color: $gray-100;
    }
  }
  tbody tr:last-child {
    th,
    td {
      border-bottom: none;
    }
  }
}


  
  /*== Table Responsive ==*/
  
  .table-rep-plugin {
    .btn-toolbar {
        display: block;
    }
    .btn-default {
        border: none;
        background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
        color: $white;
        margin-right: 5px;
        box-shadow: 0 7px 14px 0 rgba($primary, 0.5);
        transition: .3s ease-out;
        &:hover,
        &:focus {
            color: $white;
            box-shadow: 0 0 0 2px rgba($primary,.3);
        }
    }
    .btn-default.btn-primary:active,
    .btn-default.btn-primary:hover,
    .btn-default.btn-primary:focus {
        border: none !important;
        background: linear-gradient(14deg, $primary 0%, rgba($primary, 0.6));
        color: $white;
        box-shadow: 0 0 0 2px rgba($primary,.3);
    }
    tbody th {
        font-size: 14px;
        font-weight: normal;
    }

    table.focus-on tbody tr.focused th, 
    table.focus-on tbody tr.focused td {
      background-color: $primary;
      color: $white;
  }
}




/* Sticky Header */
.fixed-solution .sticky-table-header {
  top: 70px !important;
  background-color: $gray-100;
  border-top: 1px solid $border_2;
  border-bottom: 1px solid $border_2;
}

.table-responsive[data-pattern="priority-columns"]{
  border: 1px solid $border_2;
}

.attendance-table{
  .table thead tr th{
    padding: 5px;
  }
}


.tabulator-row {
  border-bottom: 1px solid $table-border-color;
  min-height: 0;
  background-color: $card-bg;
  .tabulator-cell{
    padding: $table-cell-padding-y;
    div[data-max="100"]{
      height: 5px !important;
    }
  }
  .tabulator-responsive-collapse {
    table{
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      tr {
        td{
          font-weight: $font-weight-normal;
        }
      }
      
    }
  }
  &.tabulator-selectable:hover{
    background-color: darken($card-bg, 2%);
  }
}

.tabulator{
  font-size: $font-size-base;
  background-color: $card-bg;
  .tabulator-header {
    border-bottom: 1px solid $table-border-color;
    border-top: 0;
    background-color: $gray-200;
    .tabulator-col{
      background-color: $table-bg;
      font-weight: $font-weight-semibold;
      .tabulator-col-content{
        padding: $table-cell-padding-y;
      }
      &.tabulator-sortable[aria-sort="none"]{
        .tabulator-col-content{
          .tabulator-col-sorter{
            .tabulator-arrow{
              border-bottom: 6px solid $gray-400;
            }
          }
        }
      }
      &.tabulator-sortable{
        &:hover{
          background-color: $table-bg;
        }
      }
    }
  }
  .tabulator-footer {
    border-top: 1px solid $table-border-color;
    background-color: $card-bg;
    .tabulator-page{
      padding: $input-btn-padding-y $input-btn-padding-x;
      font-size: $font-size-base;
      border: 1px solid $border_2;
      border-right: none;
      background: lighten($card-bg, 2%);
      color: $body-color;
      &:disabled{
        background-color: $card-bg;
        border-color: $border_2;
        color: $gray-500;
      }
      &[data-page="last"]{
        border: 1px solid $border_2;
      }
      &:not(.disabled):hover{
        background-color: $card-bg;
        border-color: $border_2;
        color: $gray-500;
      }
    }
  }
}