//
// Email
// 

.email-leftbar {
  width: 220px;
  float: left;
  .mail-list {
    a {
      display: block;
      color: $gray-700;
      font-size: 13px;
      line-height: 24px;
      padding: 5px;
      i{
        color: $gray-500;
      }
    }
    a:hover i,
    a.active i,
    a:hover,
    a.active {
      color: $primary;
    }
  }
  .chat-user-box {
    p.user-title {
      font-size: 13px;
      color: $dark;
      font-weight: 500;
    }
    p {
      font-size: 12px;
    }
  }
}

.email-rightbar {
  margin-left: 240px;
}

.message-list {
  display: block;
  padding-left: 0;

  li {
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    transition-duration: .3s;

    a{
      color: $gray-600;
    }

    &:hover {
      background: $gray-200;
      transition-duration: .05s;
    }

    .col-mail {
      float: left;
      position: relative;
    }

    .col-mail-1 {
      width: 320px;

      .star-toggle,
      .checkbox-wrapper-mail,
      .dot {
        display: block;
        float: left;
      }

      .dot {
        border: 4px solid transparent;
        border-radius: 100px;
        margin: 22px 26px 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0;
      }

      .checkbox-wrapper-mail {
        margin: 15px 10px 0 20px;
      }

      .star-toggle {
        margin-top: 18px;
        font-size: 16px;
        margin-left: 5px;
      }

      .title {
        position: absolute;
        top: 0;
        left: 110px;
        right: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 14px 0 0;
        
      }
    }

    .col-mail-2 {
      position: absolute;
      top: 0;
      left: 320px;
      right: 0;
      bottom: 0;

      .subject,
      .date {
        position: absolute;
        top: 0;
      }

      .subject {
        left: 0;
        right: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .date {
        right: 0;
        width: 170px;
        padding-left: 80px;
      }
    }

  }

  li.active,
  li.active:hover {
    box-shadow: inset 3px 0 0 $primary;
  }

  li.unread  {
    background-color: $gray-200;
    a{
      color: darken($dark,5%);
    }
  }

  .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    box-shadow: inset 0 0 0 1px $gray-400;
    border-radius: 1px;

    input {
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ label {
      opacity: 1;
    }

    label {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      cursor: pointer;
      opacity: 0;
      margin-bottom: 0;
      transition-duration: .05s;
      top: 0;
      &:before {
        content: "\F12C";
        font-family: "Material Design Icons";
        top: 0;
        height: 20px;
        color: darken($dark,5%);
        width: 20px;
        position: absolute;
        margin-top: -16px;
        left: 4px;
        font-size: 13px;
      }
    }
  }
}





@media (max-width: 767px) {
  .email-leftbar {
    float: none;
    width: 100%;
  }
  .email-rightbar {
    margin: 0;
  }
}
 

.note-editor{
  &.note-frame {
    border: 1px solid $border_2;
    .note-editing-area {
      .note-editable {
        padding: 10px;
        overflow: auto;
        color: $gray-700;
        background-color: $card-bg;
      }
    }
  }
}


.note-editor.note-frame .note-statusbar .note-resizebar{
  background-color: $body-bg;
}