/*
Template Name: Metrica - Admin & Dashboard Template
Author: Mannatthemes
Version: 2.2.0
File: footer Css
*/


.footer {
  bottom: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  left: 0;
  color: $gray-600;
  border-top: 1px solid $border;
}